import { useGSAP } from '@gsap/react';
import { Box, Flex } from '@inflearn/ds-react';
import gsap from 'gsap';
import { useRef } from 'react';
import { horizontalLoop } from '../../../../utils/horizontalLoop';
import { URLS } from '../../../../utils/urls';
import { SessionCard } from './SessionCard';

type Props = {
  data: Array<{
    id: number;
    title: string;
    tagName: string;
    position: string;
    staredAt: string;
    thumbnailUrl: string;
  }>;
  color: string;
  backgroundUrl: string;
  reversed?: boolean;
};

export function SessionCards({ data, color, reversed = false, backgroundUrl }: Props) {
  const ref = useRef<HTMLUListElement>(null);

  useGSAP(
    (context, contextSafe) => {
      if (!data) {
        return;
      }

      const marquee = ref.current;

      if (!(marquee instanceof HTMLElement)) {
        return;
      }

      const links = marquee.querySelectorAll('.session-card__wrapper');

      if (links.length === 0) {
        return;
      }

      const loop = horizontalLoop(links, {
        repeat: -1,
        speed: 1,
        draggable: true,
        reversed,
        paddingRight: parseFloat(gsap.getProperty(links[0], 'marginRight', 'px') as string)
      });

      if (!contextSafe) {
        return;
      }

      const pause = contextSafe(() => {
        loop.pause();
      });

      const play = contextSafe(() => {
        loop.play();
      });

      marquee.addEventListener('mouseenter', pause);

      marquee.addEventListener('mouseleave', play);

      marquee.addEventListener('touchstart', pause);

      marquee.addEventListener('touchend', play);

      return () => {
        marquee.removeEventListener('mouseenter', pause);
        marquee.removeEventListener('mouseleave', play);
        marquee.removeEventListener('touchstart', pause);
        marquee.removeEventListener('touchend', play);
      };
    },
    {
      dependencies: [data],
      scope: ref
    }
  );

  return (
    <Flex component="ul" ref={ref}>
      {data.map((value, index) => (
        <Box component="li" key={index} className="session-card__wrapper" mr={16}>
          <a href={URLS.INFCON_2024__SESSION_DETAIL(value.id)} target="_blank" rel="noreferrer">
            <SessionCard
              backgroundUrl={backgroundUrl}
              color={color}
              title={value.title}
              tagName={value.tagName}
              position={value.position}
              startedAt={value.staredAt}
              thumbnailUrl={value.thumbnailUrl}
            />
          </a>
        </Box>
      ))}
    </Flex>
  );
}
