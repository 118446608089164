import { useAnalytics } from '@inflearn/analytics';
import { useCallback, useMemo } from 'react';

import { GATSBY_MIXPANEL_TOKEN } from '../utils/env';
import useV1AccountProfileQuery, { Code } from './useV1AccountProfileQuery';

export const useTrackMixpanelEvent = () => {
  const { analytics } = useAnalytics();
  const { data: sessionData, isLoading } = useV1AccountProfileQuery();

  const session = sessionData?.data;

  const userProperty = useMemo(() => {
    if (!session) {
      return null;
    }

    return {
      user_id: session.id,
      role_b2b: !!session.roles.find(
        ({ code }) =>
          code === Code.GROUP_MANAGER || code === Code.GROUP_USER || code === Code.GROUP_MEMBER
      ),
      role_b2c: !!session.roles.find(({ code }) => code === Code.B2C),
      role_admin: !!session.roles.find(({ code }) => code === Code.ADMIN),
      role_instructor: !!session.roles.find(({ code }) => code === Code.INSTRUCTOR),
      is_marketing: session.isMarketingAllowed,
      is_newsletter: session.isNewsEmailAllowed,
      user_created_date: session.createdAt,
      user_inactive_date: session.inactiveAt
    };
  }, [session]);

  const trackMixpanelEvent = useCallback(
    <K extends EventNames>(
      eventName: K,
      props?: EventPropsMap[K] extends undefined ? never : EventPropsMap[K]
    ) => {
      if (isLoading) {
        return;
      }

      analytics.track(eventName, {
        mixpanelToken: GATSBY_MIXPANEL_TOKEN,
        mxp___user: userProperty,
        ...props
      });
    },
    [isLoading, analytics, userProperty]
  );

  const emptyTrackMixpanelEvent = useCallback(
    <K extends EventNames>(
      eventName: K,
      props?: EventPropsMap[K] extends undefined ? never : EventPropsMap[K]
    ) => {
      void analytics.track(eventName, {
        mixpanelToken: GATSBY_MIXPANEL_TOKEN,
        mxp___user: userProperty,
        ...props
      });
    },
    [userProperty, analytics]
  );

  return {
    emptyTrackMixpanelEvent,
    trackMixpanelEvent,
    isReady: !isLoading
  };
};

export const eventNames = {
  VIEW_INFCON_MAIN: 'view_infcon_main',
  VIEW_INFCON_SESSION_SDP: 'view_infcon_session_sdp',
  CLICK_INFCON_MYPAGE_SHARED: 'click_infcon_mypage_shared',
  CLICK_INFCON_SHAREDPAGE_SCHEDULE: 'click_infcon_sharedpage_schedule',
  CLICK_INFCON_RALLIT_HUB: 'click_infcon_rallit_hub'
} as const;

export type EventNames = (typeof eventNames)[keyof typeof eventNames];

export type Nullable<Type> = {
  [key in keyof Type]: Type[key] | null;
};

export interface EventPropsMap extends Record<EventNames, Record<string, unknown>> {
  view_infcon_main: {
    url_path?: 'main' | 'session' | 'event' | 'faq' | 'mypage' | 'shared_page';
    has_infcon_coupon: boolean;
    is_infcon_registered: boolean;
  };
  view_infcon_session_sdp: {
    speaker_name: string;
    session_tags: string[];
    post_title: string;
  };
  click_infcon_mypage_shared: {
    mission_step: number;
  };
  click_infcon_sharedpage_schedule: {
    shared_page_url: string;
  };
  click_infcon_rallit_hub: {
    click_spot: 'mypage' | 'shared_page';
  };
}
