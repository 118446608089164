import { css } from '@emotion/react';
import { Box, COLOR_PALETTE, Flex, Text } from '@inflearn/ds-react';
import { Image } from '@mantine/core';

type Props = {
  title: string;
  tagName: string;
  position: string;
  startedAt: string;
  thumbnailUrl: string;
  color: string;
  backgroundUrl: string;
};

export function SessionCard({
  title,
  tagName,
  position,
  startedAt,
  thumbnailUrl,
  color,
  backgroundUrl
}: Props) {
  return (
    <Box
      css={{
        padding: '20px 20px 0 0',
        backgroundColor: color,
        background: `url(${backgroundUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: 320,
        height: 320
      }}>
      <Flex
        w="100%"
        h="100%"
        direction="column"
        justify="space-between"
        p={20}
        css={{
          backgroundColor: 'white'
        }}>
        <Text
          css={{
            color: COLOR_PALETTE.gray[9],
            fontSize: 18,
            fontWeight: 500,
            lineHeight: '140%'
          }}>
          {title}
        </Text>
        <Flex justify="space-between">
          <Text css={styleCardText(color)}>{tagName}</Text>
          <Box>
            <Text css={styleCardText(color)}>{position}</Text>
            <Text css={styleCardText(color)}>{startedAt}</Text>
          </Box>
        </Flex>
        <Image
          radius={50}
          src={thumbnailUrl}
          alt="speaker"
          fit="cover"
          styles={{
            imageWrapper: {
              width: 80,
              height: 80
            },
            image: {
              width: '100%',
              height: '100% !important'
            }
          }}
        />
      </Flex>
    </Box>
  );
}

const styleCardText = (color: string) =>
  css({
    color,
    fontSize: 16,
    fontWeight: 600,
    lineHeight: '140%'
  });
