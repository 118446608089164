import { COLOR_PALETTE, desktopFirstMediaQuery, Flex, Text } from '@inflearn/ds-react';
import { memo } from 'react';
import { FlipClockCard } from './FlipClockCard';

type Props = {
  label: string;
  time: number;
  previousTime: number;
  shuffle: boolean;
};

export const FlipClockSegment = memo(_FlipClockSegment);

function _FlipClockSegment({ label, time, previousTime, shuffle }: Props) {
  return (
    <Flex
      direction="column"
      css={[
        {
          padding: '16px 0',
          borderRadius: 8,
          backgroundColor: COLOR_PALETTE.dark[8],
          [desktopFirstMediaQuery.mobile]: {
            padding: '8px 0'
          }
        }
      ]}>
      <FlipClockCard time={time} previousTime={previousTime} shuffle={shuffle} />
      <Text
        css={{
          color: 'rgba(255, 255, 255, 0.50)',
          textAlign: 'center',
          fontSize: 18,
          fontWeight: 600,
          lineHeight: '140%',
          [desktopFirstMediaQuery.mobile]: {
            fontSize: 12
          }
        }}>
        {label}
      </Text>
    </Flex>
  );
}
