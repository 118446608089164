import { useQuery, type UseQueryResult } from 'react-query';
import { accountFetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

type V1AccountProfileResponse = {
  statusCode?: string;
  message?: string;
  data?: AccountProfileResponseDto;
};

export const enum Code {
  'B2C' = 'B2C',
  'GROUP_MEMBER' = 'GROUP_MEMBER',
  'GROUP_USER' = 'GROUP_USER',
  'GROUP_MANAGER' = 'GROUP_MANAGER',
  'INSTRUCTOR' = 'INSTRUCTOR',
  'ADMIN' = 'ADMIN'
}

const enum Name {
  '일반회원' = '일반회원',
  '기업회원' = '기업회원',
  '기업관리자' = '기업관리자',
  '지식공유자' = '지식공유자',
  '어드민' = '어드민'
}

export interface UserRoleCategory {
  code: Code;
  name: Name;
}

/** 계정 유형 */
export const enum Role {
  'B2C' = 'B2C',
  'GROUP_MEMBER' = 'GROUP_MEMBER',
  'GROUP_USER' = 'GROUP_USER',
  'GROUP_MANAGER' = 'GROUP_MANAGER',
  'INSTRUCTOR' = 'INSTRUCTOR',
  'ADMIN' = 'ADMIN'
}

type AccountProfileResponseDto = {
  /**
   * 계정 고유번호
   */
  id: number;
  /**
   * 닉네임
   */
  name: string;
  /**
   * 이메일
   */
  email: string;
  /**
   * 가입 일자
   */
  joinedAt: string;
  /**
   * 관리 중인 그룹 고유번호
   */
  manageGroupId?: number;
  /**
   * 그룹 소속 유저 여부
   */
  isGroupMember: boolean;
  /**
   * 실명인증 여부
   */
  isVerifiedRealName: boolean;
  /**
   * 계정 유형
   */
  roles: UserRoleCategory[];
  /**
   * 프로필 사진 URL
   */
  thumbnailUrl: string;
  /**
   * 관리 중인 그룹 고유번호 manageGroupId와 동일한 값을 가집니다.
   */
  groupId?: number;
  /**
   * 실명인증 여부 isVerifiedRealName가 동일한 값을 가짐
   */
  isCertification: boolean;
  role: Role;
  /**
   * 회원가입일자
   */
  createdAt: string;
  /**
   * 휴면 일자
   */
  inactiveAt?: string;
  /**
   * 마케팅 동의여부
   */
  isMarketingAllowed: boolean;
  /**
   * 뉴스레터 동의여부
   */
  isNewsEmailAllowed: boolean;
};

const useV1AccountProfileQuery = (): UseQueryResult<V1AccountProfileResponse> => {
  return useQuery(
    [API_URLS.V1_ACCOUNT_PROFILE],
    async () => {
      try {
        const response = await accountFetcher.get<V1AccountProfileResponse>(
          API_URLS.V1_ACCOUNT_PROFILE
        );

        if (response.statusCode === 'UNAUTHORIZED') {
          return;
        }

        if (response.data === undefined) {
          throw new Error();
        }

        return response;
      } catch (error) {
        logger.error(error);
        throw error;
      }
    },
    {
      staleTime: Infinity
    }
  );
};

export default useV1AccountProfileQuery;
