import { useQuery } from 'react-query';
import { fetcher, logger } from '../utils/Fetcher';
import { API_URLS } from '../utils/urls';

type ApplicationStatusResponse = {
  ok: boolean;
  data?: {
    isApplicant: boolean;
    hasCoupon: boolean;
  };
};

const useApplicationStatus = () => {
  return useQuery(API_URLS.APPLICANT_STATUS, async () => {
    try {
      const response = await fetcher.get<ApplicationStatusResponse>(API_URLS.APPLICANT_STATUS);

      return response;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  });
};

export default useApplicationStatus;
