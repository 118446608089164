import { Box, desktopFirstMediaQuery, Flex } from '@inflearn/ds-react';

import { INFCON_2024_COLOR_PALETTE } from '../../../../../styles/color';

import dayjs from 'dayjs';
import { useState } from 'react';
import { useTimer } from '../useTimer';
import { FlipClockTitle } from './FlipClockTitle';
import { FlipClockSegment } from './FlipClockSegment';
import { 이차_디데이, 일차_디데이 } from '../../../../../fixtures/dDay';

export function FlipClock() {
  const 일차디데이 = dayjs(일차_디데이).toDate();
  const 이차디데이 = dayjs(이차_디데이).toDate();

  const getExpiryTimestamp = () => {
    const now = dayjs().toDate();

    if (dayjs(now).isBefore(일차디데이)) {
      return 일차디데이;
    }

    if (dayjs(now).isBefore(이차디데이)) {
      return 이차디데이;
    }

    return undefined;
  };
  const [expiryTimestamp, setExpiryTimestamp] = useState<Date | undefined>(getExpiryTimestamp);

  const timer = useTimer({
    expiryTimestamp,
    onExpire: () => {
      setExpiryTimestamp(getExpiryTimestamp());
    }
  });
  const is일차디데이 = dayjs(일차디데이).isSame(expiryTimestamp);
  const is이차디데이 = dayjs(이차디데이).isSame(expiryTimestamp);

  return (
    <Flex
      direction="column"
      css={{
        padding: '16px 24px 24px 24px',
        width: '100%',
        backgroundColor: INFCON_2024_COLOR_PALETTE.blue,
        borderRadius: 16,
        gap: 16,
        [desktopFirstMediaQuery.mobile]: {
          padding: 16
        }
      }}>
      <FlipClockTitle is일차디데이={is일차디데이} is이차디데이={is이차디데이} />
      <Box
        css={{
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 1fr)',
          gap: 8,
          [desktopFirstMediaQuery.mobile]: {
            gridTemplateColumns: 'repeat(2, 1fr)'
          }
        }}>
        <FlipClockSegment
          shuffle={timer.current.isShuffleDays}
          label="Days"
          time={timer.current.days}
          previousTime={timer.previous.days}
        />
        <FlipClockSegment
          shuffle={timer.current.isShuffleHours}
          label="Hours"
          time={timer.current.hours}
          previousTime={timer.previous.hours}
        />
        <FlipClockSegment
          shuffle={timer.current.isShuffleMinutes}
          label="Minutes"
          time={timer.current.minutes}
          previousTime={timer.previous.minutes}
        />
        <FlipClockSegment
          shuffle={timer.current.isShuffleSeconds}
          label="Seconds"
          time={timer.current.seconds}
          previousTime={timer.previous.seconds}
        />
      </Box>
    </Flex>
  );
}
