import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { useEffect, useRef, useState } from 'react';

dayjs.extend(duration);

type Props = {
  expiryTimestamp?: Date;
  onExpire?: () => void;
};

export function useTimer({ expiryTimestamp, onExpire }: Props) {
  const getTimer = (timer?: {
    days: number;
    isShuffleDays: boolean;
    hours: number;
    isShuffleHours: boolean;
    minutes: number;
    isShuffleMinutes: boolean;
    seconds: number;
    isShuffleSeconds: boolean;
  }) => {
    if (!expiryTimestamp) {
      return {
        days: 0,
        isShuffleDays: true,
        hours: 0,
        isShuffleHours: true,
        minutes: 0,
        isShuffleMinutes: true,
        seconds: 0,
        isShuffleSeconds: true
      };
    }

    const now = dayjs();
    const duration = dayjs.duration(dayjs(expiryTimestamp).diff(now));

    return {
      days: Math.floor(duration.asDays()) > 0 ? Math.floor(duration.asDays()) : 0,
      isShuffleDays: timer?.isShuffleDays ?? true,
      hours: duration.hours() > 0 ? duration.hours() : 0,
      isShuffleHours: timer?.isShuffleHours ?? true,
      minutes: duration.minutes() > 0 ? duration.minutes() : 0,
      isShuffleMinutes: timer?.isShuffleMinutes ?? true,
      seconds: duration.seconds() > 0 ? duration.seconds() : 0,
      isShuffleSeconds: timer?.isShuffleSeconds ?? true
    };
  };

  const [currentTimer, setCurrentTimer] = useState(getTimer());
  const previousTimerRef = useRef(currentTimer);

  useEffect(() => {
    if (!expiryTimestamp) {
      return;
    }

    const timer = setInterval(() => {
      if (dayjs().isAfter(expiryTimestamp)) {
        onExpire?.();
        clearInterval(timer);
        return;
      }

      setCurrentTimer((prev) => {
        //  TODO - Molly: 로직 리팩토링 필요

        previousTimerRef.current = prev;

        const newTimer = getTimer(prev);

        if (newTimer.days !== prev.days) {
          newTimer.isShuffleDays = !prev.isShuffleDays;
        }

        if (newTimer.hours !== prev.hours) {
          newTimer.isShuffleHours = !prev.isShuffleHours;
        }

        if (newTimer.minutes !== prev.minutes) {
          newTimer.isShuffleMinutes = !prev.isShuffleMinutes;
        }

        if (newTimer.seconds !== prev.seconds) {
          newTimer.isShuffleSeconds = !prev.isShuffleSeconds;
        }

        return newTimer;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [expiryTimestamp]);

  return {
    current: currentTimer,
    previous: previousTimerRef.current
  };
}
