import { css } from '@emotion/react';
import { useGSAP } from '@gsap/react';
import { Box, COLOR_PALETTE, desktopFirstMediaQuery, Flex, Text } from '@inflearn/ds-react';
import { useEffect, useRef } from 'react';
import { FlipClock } from './FlipClock/FlipClock';
import gsap from 'gsap';

export function InvitationSection() {
  const flipClockWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = () => {
      if (!flipClockWrapperRef.current) {
        return;
      }

      flipClockWrapperRef.current.style.top = `-${flipClockWrapperRef.current.offsetHeight / 2}px`;
    };

    listener();

    window.addEventListener('resize', listener);

    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  useGSAP(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.invitation-section',
        start: 'top 50%',
        end: 'bottom 50%',
        toggleActions: 'play none none reverse'
      },
      defaults: {
        duration: 0.2,
        ease: 'power2.inOut'
      }
    });

    gsap.from('.invitation-section__flip-clock-wrapper', {
      scrollTrigger: {
        trigger: '.invitation-section__flip-clock-wrapper',
        start: 'top 50%',
        end: 'bottom 50%',
        toggleActions: 'play none none reverse'
      },
      opacity: 0,
      y: 10
    });

    timeline
      .from('.invitation-section__text1', { opacity: 0, y: 10 })
      .from('.invitation-section__text2', { opacity: 0, y: 10 })
      .from('.invitation-section__text3', { opacity: 0, y: 10 });
  });

  return (
    <Flex
      className="invitation-section"
      pos="relative"
      component="section"
      align="center"
      justify="center"
      direction="column"
      css={{
        padding: '240px 0 200px 0px',
        [desktopFirstMediaQuery.tablet]: {
          padding: '200px 32px 160px 32px'
        },
        [desktopFirstMediaQuery.tablet]: {
          padding: '200px 16px 160px 16px'
        }
      }}>
      <Box
        ref={flipClockWrapperRef}
        className="invitation-section__flip-clock-wrapper"
        css={{
          position: 'absolute',
          width: '100%',
          maxWidth: 720,
          [desktopFirstMediaQuery.tablet]: {
            padding: 32
          },
          [desktopFirstMediaQuery.mobile]: {
            padding: 16,
            maxWidth: 320
          }
        }}>
        <FlipClock />
      </Box>
      <Text
        className="invitation-section__text1"
        css={{
          color: '#05B9DF',
          textAlign: 'center',
          fontSize: 40,
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '140%',
          [desktopFirstMediaQuery.tablet]: {
            fontSize: 32
          },
          [desktopFirstMediaQuery.mobile]: {
            fontSize: 24
          }
        }}>
        올해로 벌써 세 번째!
      </Text>
      <Text className="invitation-section__text2" css={styleText}>
        인프런이 만드는 IT인의 축제
      </Text>
      <Text className="invitation-section__text3" css={styleText}>
        인프콘으로 초대합니다.
      </Text>
    </Flex>
  );
}

const styleText = css({
  color: COLOR_PALETTE.gray[9],
  textAlign: 'center',
  fontSize: 60,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '140%',
  [desktopFirstMediaQuery.tablet]: {
    fontSize: 52
  },
  [desktopFirstMediaQuery.mobile]: {
    fontSize: 28
  }
});
