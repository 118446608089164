import { useGSAP } from '@gsap/react';
import { AspectRatio, Box, COLOR_PALETTE, Flex } from '@inflearn/ds-react';
import gsap from 'gsap';
import { getCdnUrl } from '../../../utils/getCdnUrl';
export function VideoSection() {
  useGSAP(() => {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.video-section',
        start: 'top 64px',
        end: 'bottom 64px',
        toggleActions: 'play none none reverse',
        pin: true
      },
      defaults: {
        duration: 0.2,
        ease: 'power2.inOut'
      }
    });

    timeline.from('.video-section', {
      yPercent: -100,
      opacity: 0,
      ease: 'power2.inOut'
    });
  });

  return (
    <Flex
      className="panel video-section"
      component="section"
      align="center"
      justify="center"
      css={{
        backgroundColor: COLOR_PALETTE.dark[9],
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 64px)',
        overflow: 'hidden'
      }}>
      <AspectRatio ratio={16 / 9} w="100%" className="video-section__video-wrapper" pos="relative">
        <Box
          component="video"
          w="100%"
          h="100%"
          src={getCdnUrl('infcon-2024-main/main-video.mp4')}
          autoPlay
          muted
          loop
          playsInline
        />
      </AspectRatio>
    </Flex>
  );
}
