import { css } from '@emotion/react';
import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { useGSAP } from '@gsap/react';
import {
  Box,
  Button,
  COLOR_PALETTE,
  desktopFirstMediaQuery,
  Flex,
  Icon,
  Text
} from '@inflearn/ds-react';
import gsap from 'gsap';
import { type PageResponse } from '../../../../dtos/PageDto';
import useSessionProgramPages from '../../../../hooks/useSessionProgramPages';
import useSessionSpeakerPages from '../../../../hooks/useSessionSpeakerPages';
import { getCdnUrl } from '../../../../utils/getCdnUrl';
import { prefix, URLS } from '../../../../utils/urls';
import { SessionGroup } from './SessionGroup';

export function SessionSection() {
  useGSAP(() => {
    // NOTE: text animation
    const textTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: '.session-section',
        start: 'top 60%',
        end: 'bottom 60%',
        toggleActions: 'play none none reverse'
      },
      defaults: {
        duration: 0.2,
        ease: 'power2.inOut'
      }
    });

    textTimeline
      .from('.session-section__text1', { opacity: 0, y: 10 })
      .from('.session-section__text2', { opacity: 0, y: 10 })
      .from('.session-section__text3', { opacity: 0, y: 10 });

    // gsap.utils.toArray('.session-section__session-group').forEach((group, index) => {
    //   if (!(group instanceof HTMLElement)) {
    //     return;
    //   }

    //   gsap.from(group, {
    //     scrollTrigger: {
    //       trigger: group,
    //       start: 'top 80%',
    //       end: 'bottom 80%',
    //       toggleActions: 'play none none none'
    //     },
    //     opacity: 0,
    //     xPercent: index % 2 === 0 ? -10 : 10
    //   });
    // });

    gsap.from('.session-section__button', {
      scrollTrigger: {
        trigger: '.session-section__button',
        start: 'top 80%',
        end: 'bottom 80%',
        toggleActions: 'play none none reverse'
      },
      opacity: 0,
      y: 10
    });
  });

  const { data: speakersData } = useSessionSpeakerPages(2024);
  const { data: programsData } = useSessionProgramPages(2024);
  const speakers = speakersData?.data ?? [];
  const programs = programsData?.data ?? [];

  const tagNamesGroupForParsing = [
    [
      '기술/성장',
      '컴퓨터공학',
      '프로그래밍 언어',
      '프론트엔드',
      '백엔드',
      '데브옵스/인프라',
      '아키텍처',
      '모바일'
    ],
    ['프로덕트/성장', '디자인/성장', '디자인시스템'],
    ['협업/생산성', '커리어/성장']
  ];
  const tagNamesGroupForUi = [
    ['프론트엔드', '백엔드', '아키텍처', '데브옵스/인프라', '모바일', 'AI'],
    ['프로덕트', '디자인', '디자인시스템', '데이터', '브랜드', 'AI'],
    ['커리어', '협업', '조직문화', '사이드프로젝트', 'AI']
  ];
  const parsedSessions = parsed([...speakers, ...programs], tagNamesGroupForParsing);

  const sessionGroupColors = [
    (index: number) => COLOR_PALETTE.blue[index],
    (index: number) => COLOR_PALETTE.rallit[index],
    (index: number) => COLOR_PALETTE.violet[index]
  ];
  const backgroundUrls = [
    getCdnUrl('infcon-2024-main/2_card_b.png'),
    getCdnUrl('infcon-2024-main/2_card_g.png'),
    getCdnUrl('infcon-2024-main/2_card_v.png')
  ];

  return (
    <Flex
      className="session-section panel"
      component="section"
      direction="column"
      gap={80}
      css={{
        padding: '160px 0',
        backgroundColor: COLOR_PALETTE.dark[9],
        [desktopFirstMediaQuery.mobile]: {
          padding: '80px 0'
        }
      }}>
      <Box>
        <Text
          className="session-section__text1"
          css={{
            color: '#00A2DF',
            textAlign: 'center',
            fontSize: 30,
            fontWeight: 600,
            lineHeight: '140%',
            [desktopFirstMediaQuery.tablet]: {
              fontSize: 24
            },
            [desktopFirstMediaQuery.mobile]: {
              fontSize: 18
            }
          }}>
          가장 중요한
        </Text>
        <Text className="session-section__text2" css={styleText}>
          올해 세션
        </Text>
        <Text className="session-section__text3" css={styleText}>
          기대해도 좋아요
        </Text>
      </Box>
      {parsedSessions.map((group, index) => (
        <SessionGroup
          backgroundUrl={backgroundUrls[index]}
          key={index}
          sessions={group}
          tagNames={tagNamesGroupForUi[index]}
          getColor={sessionGroupColors[index]}
          reversed={false}
        />
      ))}
      <Button
        className="session-section__button"
        variant="default"
        size="lg"
        component={'a'}
        href={`${prefix}${URLS.INFCON_2024__SESSION}`}
        w="fit-content"
        mx="auto"
        rightIcon={<Icon icon={faArrowUpRight} />}>
        전체 발표/프로그램
      </Button>
    </Flex>
  );
}

const styleText = css({
  color: 'white',
  textAlign: 'center',
  fontSize: 60,
  fontWeight: 700,
  lineHeight: '140%',
  [desktopFirstMediaQuery.tablet]: {
    fontSize: 52
  },
  [desktopFirstMediaQuery.mobile]: {
    fontSize: 32
  }
});

function parsed(data: PageResponse[], criteria: string[][]) {
  const result: PageResponse[][] = [];

  criteria.forEach((criterion) => {
    const matchedGroup = data.filter((item) => {
      return criterion.some((c) => (item.data.trackName ?? '').split(/\s*,\s*/)[2] === c);
    });

    result.push(matchedGroup);
  });

  return result;
}
