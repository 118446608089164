import { useEffect } from 'react';
import { useLocation } from '../layouts/Infcon2024Layout';
import { URLS } from '../utils/urls';
import useApplicationStatus from './useApplicationStatus';
import { useTrackMixpanelEvent, eventNames } from './useTrackMixpanelEvent';

export function useViewInfconMain() {
  const { trackMixpanelEvent, isReady } = useTrackMixpanelEvent();
  const { data } = useApplicationStatus();
  const location = useLocation();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const getUrlPath = (pathname: string) => {
      if (pathname.includes(URLS.INFCON_2024__SESSION)) {
        return 'session';
      }

      if (pathname.includes(URLS.INFCON_2024__EVENT)) {
        return 'event';
      }

      if (pathname.includes(URLS.INFCON_2024__FAQ)) {
        return 'faq';
      }

      if (pathname.includes(URLS.INFCON_2024__MYPAGE)) {
        return 'mypage';
      }

      if (pathname.includes(URLS.INFCON_2024_SHARE)) {
        return 'shared_page';
      }

      if (pathname.includes(URLS.INFCON_2024__HOME)) {
        return 'main';
      }

      return undefined;
    };

    if (!isReady) {
      return;
    }

    if (data && !data?.ok) {
      trackMixpanelEvent(eventNames.VIEW_INFCON_MAIN, {
        url_path: getUrlPath(location.pathname),
        has_infcon_coupon: false,
        is_infcon_registered: false
      });

      return;
    }

    if (data?.data && data?.ok) {
      trackMixpanelEvent(eventNames.VIEW_INFCON_MAIN, {
        url_path: getUrlPath(location.pathname),
        has_infcon_coupon: data.data.hasCoupon,
        is_infcon_registered: data.data.isApplicant
      });
    }
  }, [data, isReady]);

  return null;
}
