import { useGSAP } from '@gsap/react';
import { Box, Flex, Text } from '@inflearn/ds-react';
import gsap from 'gsap';
import { Fragment, useRef } from 'react';
import { horizontalLoop } from '../../../utils/horizontalLoop';

type Props = {
  backgroundColor: string;
};

export function MovingBannerSection({ backgroundColor }: Props) {
  const ref = useRef(null);

  useGSAP(
    () => {
      const links = document.querySelectorAll('.moving-banner-section__text');

      gsap.from(ref.current, {
        scrollTrigger: {
          trigger: ref.current,
          start: 'top 90%',
          end: 'bottom 90%',
          toggleActions: 'play none none reverse'
        },
        opacity: 0,
        duration: 0.2
      });

      horizontalLoop(links, {
        repeat: -1,
        speed: 1 + 0.5,
        draggable: true,
        reversed: false,
        paddingRight: gsap.getProperty(links[0], 'marginRight', 'px')
      });
    },
    {
      scope: ref
    }
  );

  return (
    <Box
      className="moving-banner-section"
      ref={ref}
      css={{
        backgroundColor
      }}>
      <Flex
        className="moving-banner-section__text-container"
        css={{
          color: 'white',
          fontSize: 20,
          fontWeight: 500,
          lineHeight: '140%',
          padding: '20px 0'
        }}>
        {Array.from({ length: 10 }).map((_, index) => (
          <Fragment key={index}>
            <Text mr={24} className="moving-banner-section__text" css={{ flexShrink: 0 }}>
              COEX Grand-Ballroom
            </Text>
            <Text mr={24} className="moving-banner-section__text" css={{ flexShrink: 0 }}>
              INFCON
            </Text>
            <Text mr={24} className="moving-banner-section__text" css={{ flexShrink: 0 }}>
              2024. 8. 2
            </Text>
          </Fragment>
        ))}
      </Flex>
    </Box>
  );
}
