import { Box, COLOR_PALETTE, NotificationsProvider } from '@inflearn/ds-react';
import { type HeadFC } from 'gatsby';
import { HeroSection } from '../../components/infcon-2024/main/HeroSection';
import { InvitationSection } from '../../components/infcon-2024/main/InvitationSection/InvitationSection';
import { MapSection } from '../../components/infcon-2024/main/MapSection';
import { MovingBannerSection } from '../../components/infcon-2024/main/MovingBannerSection';
import { OfficialChannelSection } from '../../components/infcon-2024/main/OfficialChannelSection';
import { SessionSection } from '../../components/infcon-2024/main/SessionSection/SessionSection';
import { ShareSection } from '../../components/infcon-2024/main/ShareSection';
import { SponsorCompaniesSection } from '../../components/infcon-2024/main/SponsorCompaniesSection';
import { VideoSection } from '../../components/infcon-2024/main/VideoSection';
import Scripts from '../../components/Scripts';
import { useViewInfconMain } from '../../hooks/useViewInfconMain';
import '../../lib/gsap';
import { INFCON_2024_COLOR_PALETTE } from '../../styles/color';
import { GATSBY_SITE_URL } from '../../utils/env';
import { getCdnUrl } from '../../utils/getCdnUrl';
import Seo from '../../utils/Seo';
import { URLS } from '../../utils/urls';

export const Head: HeadFC = () => {
  return (
    <>
      <Seo
        title="인프콘 2024 - INFCON 2024"
        description="인프런이 만드는 IT인의 축제, 인프콘에 초대합니다"
        fullUrl={`${GATSBY_SITE_URL}/conf${URLS.INFCON_2024__HOME}`}
        image={getCdnUrl('infcon-2024-main/official-og.png')}
      />
      <Scripts />
    </>
  );
};

export default function Infcon2024Page() {
  useViewInfconMain();

  return (
    <>
      <NotificationsProvider />
      <Box
        css={{
          overflowX: 'hidden'
        }}>
        <HeroSection />
        <InvitationSection />
        <MovingBannerSection backgroundColor={INFCON_2024_COLOR_PALETTE.blue} />
        <VideoSection />
        <SessionSection />
        <MovingBannerSection backgroundColor={COLOR_PALETTE.infgreen[6]} />
        <SponsorCompaniesSection />
        <MapSection />
        <OfficialChannelSection />
        <ShareSection />
        <MovingBannerSection backgroundColor={COLOR_PALETTE.dark[9]} />
      </Box>
    </>
  );
}
