import { Box, desktopFirstMediaQuery, Flex } from '@inflearn/ds-react';
import { type PageResponse } from '../../../../dtos/PageDto';
import { SessionCards as SessionsCards } from './SessionCards';
import { TagText } from './TagText';
type Props = {
  sessions: PageResponse[];
  tagNames: string[];
  getColor: (index: number) => string;
  reversed?: boolean;
  backgroundUrl: string;
};

export function SessionGroup({
  sessions,
  tagNames,
  getColor,
  reversed = false,
  backgroundUrl
}: Props) {
  return (
    <Box className="session-section__session-group">
      <Flex
        mb={24}
        align="center"
        justify="center"
        wrap="wrap"
        css={{
          gap: 16,
          [desktopFirstMediaQuery.mobile]: {
            rowGap: 8,
            columnGap: 16
          }
        }}>
        {tagNames.map((tagName) => (
          <TagText key={tagName} color={getColor(5)}>
            {tagName}
          </TagText>
        ))}
      </Flex>
      <SessionsCards
        backgroundUrl={backgroundUrl}
        reversed={reversed}
        data={sessions.map((session) => {
          return {
            id: session.id,
            title: session.title,
            tagName:
              '#' +
              (session.data.trackName ?? '').split(/\s*,\s*/)[1] +
              ', ' +
              '#' +
              (session.data.trackName ?? '').split(/\s*,\s*/)[2],
            position: session.data.sessionLocation ?? '',
            staredAt: (session.data.sessionSchedule ?? '').split(' - ')[0],
            thumbnailUrl: session.url
          };
        })}
        color={getColor(9)}
      />
    </Box>
  );
}
