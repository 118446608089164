import { desktopFirstMediaQuery, Text } from '@inflearn/ds-react';

export function TagText({ children, color }: { children: string; color: string }) {
  return (
    <Text
      css={{
        color,
        fontSize: 24,
        fontWeight: 600,
        lineHeight: '140%',
        [desktopFirstMediaQuery.mobile]: {
          fontSize: 16
        }
      }}>
      #{children}
    </Text>
  );
}
